import config from "../../Config/Config";
import Cookies from "universal-cookie";
import axios from "axios";
const cookie = new Cookies();
const authToken = cookie.get("@df_basic_token");
const baseUrl = process.env.PUBLIC_URL;

export async function getRemoveToken() {
  axios({
    method: "POST",
    url: config.apiUrl.logoutAPI,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: `token=${authToken}&client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}`,
  })
    .then((response) => {
      if (response.status === 200) {
        cookie.remove("@ev_hub_access_token", { path: "/" });
        window.localStorage.removeItem("startDate");
        window.localStorage.removeItem("endDate");
        window.localStorage.removeItem("vehicle_group");
        window.localStorage.removeItem("mpk_list");
        window.location.href = baseUrl + "/";
      }
    })
    .catch((reject) => {
      console.log(reject);
    });
}
