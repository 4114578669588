const baseUrl = process.env.PUBLIC_URL;

// eslint-disable-next-line
export default {
    // ------------------------------------------------------------
    // Public Route
    // ------------------------------------------------------------

    _login: baseUrl + "/",
    _register: baseUrl + "/register/",
    _forget_password: baseUrl + "/forget/password/",
    _change_password: baseUrl + "/change/password/",
    _new_password: baseUrl + "/new/password/",
    _privacyPolicy: baseUrl + "/polityka-prywatnosci/",
    _regulation: baseUrl + "/regulamin/",

    // ------------------------------------------------------------
    // Private Route
    // ------------------------------------------------------------
    _landing:baseUrl+"/",
    _assesment:baseUrl+"/assesment",
    _ev_news:baseUrl+"/ev-news",
    _news:baseUrl+"/ev-news/news",
    _esg:baseUrl+"/fleet-vehicle-list",
    _vehicle_documents:baseUrl+"/vehicle-documents",
    _fleet_esg:baseUrl+"/fleet-esg-data",
    _esg_reports:baseUrl+"/esg-reports",
    _survey_summary:baseUrl+"/survey-summary",
    _ev_partner:baseUrl+"/ev-partner",
    _survey:baseUrl+"/survey",
    _ev_tools:baseUrl+"/ev-tools",
    _ev_map:baseUrl+"/ev-map",
    _ev_maps:baseUrl+"/ev-map/embed",
    _map:baseUrl+"/ev-map/map",
    _evr_process:baseUrl+"/evr-process",
    _esg_dashboard:baseUrl+"/esg-dashboard",
    _individual_partner:baseUrl+"/ev-partner/:id",
    _individual_news:baseUrl+"/ev-news/:id",
    _Individual_esg:baseUrl+"/fleet-vehicle-list/:id",
    _setting:baseUrl+"/setting"
}
  