import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./Assests/Language/English/en";
import pl from "./Assests/Language/Polish/pl";

const resources = {
  pl: pl,
  "en-US": en,
  en:en
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    //  lng: "pl", // default language
    fallbackLng: ["en-US","en","en"],
    preload: ["en-US","en","en"],
    whitelist: ["en-US","","en"], // Ensure "en" is included here
    debug: false,
    initImmediate: false,
    saveMissing: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
