import React, { useState, useRef, useEffect } from "react";
import Logo from "../../Assests/Logo.png";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";
const baseUrl = process.env.PUBLIC_URL;
const Headers = () => {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null); // Create a ref for the modal
  const navigate = useNavigate();
  const getCurrentLng = () =>
    i18n.language || window.localStorage.i18nextLng || "";

  const changeLan = (lng) => {
    i18n.changeLanguage(lng);
    setShowModal(false); // Close the modal after selecting a language
    window.location.reload(false);
  };

  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false); // Close modal if click is outside of it
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <header className="flex items-center justify-between p-5">
      <img src={Logo} alt="Lafarge Logo" className="px-2 w-[175px] h-[29px]" onClick={()=>navigate(baseUrl+"/")} />

      <div className="relative">
        <button
          className="font-[400] text-[12px] text-black font-inter cursor-pointer border border-[#E4E4E7] py-2 px-3 shadow-md rounded-md "
          onClick={() => setShowModal(!showModal)}
        >
          {getCurrentLng() === "en-US" ? "English" : "Polish"}
        </button>

        {showModal && (
          <div
            ref={modalRef} // Attach ref to the modal container
            className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg z-10"
          >
            <ul>
              <li
                className={`p-2 cursor-pointer hover:bg-gray-200 ${
                  getCurrentLng() === "en-US" ? "font-bold text-black" : ""
                }`}
                onClick={() => changeLan("en-US")}
              >
                English
              </li>
              <li
                className={`p-2 cursor-pointer hover:bg-gray-200 ${
                  getCurrentLng() === "pl" ? "font-bold text-black" : ""
                }`}
                onClick={() => changeLan("pl")}
              >
                Polish
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Headers;
