import config from "../../Config/Config";

export async function getAPIData(api) {
  return fetch(`${config.newApi[api]}`, config.head)
    .then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

// Fetch Data as well as apply filter
export async function getFilterAPIData(api, lte, gte) {
  return fetch(
    `${config.newApi[api]}?date_gte=${gte ? gte : ""}&date_lte=${
      lte ? lte : ""
    }`,
    config.head
  )
    .then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function getAPIDataAuthDetails(api, id) {
  return fetch(
    `${config.newApi[api]}${id}/`,
    config.head
  )
    .then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export async function getAPIDataAuthDetailss(api, id) {
  return fetch(
    `${config.newApi[api]}?title=${id}`,
    config.head
  )
    .then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

const gteD = (gt, lt) => (gt === lt ? "" : gt);
export async function getReportData(gte, lte, id, api) {
  return fetch(
    `${config.newApi[api]}?fleet_entry__gte=${
      gte ? gteD(gte, lte) : ""
    }&fleet_entry__lte=${lte ? lte : ""}&id__in=${id ? id : ""}`,

    config.head
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

// Fetch Data as well as apply filter
export async function getFilterAPIIdData(api, id, lte, gte) {
  return fetch(
    `${config.newApi[api]}${id}${id ? "/" : ""}?date_gte=${
      gte ? gte : ""
    }&date_lte=${lte ? lte : ""}`,
    config.head
  )
    .then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function getCarData(api, make, model) {
    return fetch(
        `${config.newApi[api]}/?make=${make ? make : ""}&model=${model ? model : ""}`,
        config.head
    ).then((response) => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    })
    .catch((error) => {
        return Promise.reject(error);
    });
}