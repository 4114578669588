import React, { useEffect } from "react";
import Logins from "../../Assests/Login.png";
import { t } from "i18next";
import Config from "../../Config/Config";
import Headers from "../Header/Headers";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";

const cookies = new Cookies();
const baseUrl = process.env.PUBLIC_URL;

const LoginInputField = [
  {
    label: "Authentication.login",
    name: "username",
    required: true,
    placeholder: "Write your email",
    type: "email",
    max: 50,
    number: false,
  },
  {
    label: "Authentication.password",
    name: "password",
    required: true,
    placeholder: " Write your password",
    type: "password",
    types: "confirm_password",
    max: 50,
    number: false,
  },
];

const Login = () => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState("");
  const [password, setPassword] = React.useState({
    username: "",
    password: "",
  });
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
    setError("");
    setErrorPassword("");
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const re = /\S+@\S+\.\S+/;
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isEnabled = re.test(password.username);
    if (!password?.username) {
      setLoading(false);
      setError("");
      setErrorPassword("username");
      return;
    }
    if (!password?.password) {
      setLoading(false);
      setError("");
      setErrorPassword("password");
      return;
    }

    if (password?.password && password?.password?.length <= 7) {
      setLoading(false);
      setError(t("Authentication.password_error"));
      setErrorPassword("");
    } else {
      if (isEnabled) {
        setLoading(true);
        axios({
          method: "POST",
          url: Config.apiUrl.loginAPI,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept-Language": window.localStorage.i18nextLng,
          },
          data: `grant_type=${process.env.REACT_APP_GRANT_TYPE}&username=${password.username}&password=${password.password}&client_id=${process.env.REACT_APP_CLIENT_ID}`,
        })
          .then((response) => {
            setLoading(false);
            if (response.status >= 200 && response.status <= 299) {
              cookies.set("@ev_hub_access_token", response.data.access_token, {
                path: "/",
                maxAge: 36000, // 30 minutes
              });
              cookies.set(
                "@user_group",
                response?.data?.user_group === null
                  ? ""
                  : response?.data?.user_group,
                {
                  path: "/",
                  maxAge: 36000, // 30 minutes
                }
              );

              redirect();
            }
          })
          .catch((reject) => {
            if (reject.response) {
              setLoading(false);
              setError(
                reject.response.data.message
                  ? reject.response.data.message
                  : reject.response.data.error_description ===
                    "Invalid credentials given."
                  ? t("invalid_credentials")
                  : reject.response.data.error ===
                    "Invalid username or password"
                  ? t("invalid_credentials")
                  : ""
              );
            }
          });
      } else {
        setLoading(false);
        setError(t("email_error"));
        setErrorPassword("");
      }
    }
  };

  useEffect(() => {
    redirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect = () => {
    if (cookies.get("@ev_hub_access_token")) {
      navigate(baseUrl+"/esg-dashboard");
      window.location.reload();
    }
  };

  return (
    <div className="grid grid-cols-2 h-full">
      <div className="h-screen">
        <img src={Logins} alt="abcd" className="h-full w-full object-cover" />
      </div>
      <div className="flex flex-col justify-between bg-gray-100">
        <Headers />
        <div className="flex items-center justify-center grow">
          <div className="rounded-md shadow-md border flex flex-col gap-2 bg-white px-8 py-5">
            <Label className="text-2xl flex items-center justify-center">
              Login to your account
            </Label>
            <Label className="text-sm flex items-center justify-center pb-4 text-[#71717A]">
              Enter your email below to create your account
            </Label>
            {error && (
              <div
                className="bg-red-100 border w-96 border-red-400 text-red-700 px-4 py-3 rounded relative mt-4"
                role="alert"
              >
                <strong className="font-bold">{error}</strong>
              </div>
            )}
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="flex flex-col gap-5">
                {LoginInputField.map((item, i) => (
                  <div key={i + 1} className="flex flex-col gap-2">
                    <Label
                      className={`text-base ${
                        errorPassword === item.name ? "text-red-500" : ""
                      }`}
                    >
                      {t(item?.label)}
                      {item?.required && (
                        <span className="text-red-500">*</span>
                      )}
                    </Label>
                    <Input
                      type={item?.type}
                      name={item.name}
                      placeholder={item?.placeholder}
                      onChange={(event) => handleInputChange(event)}
                      className="w-96 rounded-md shadow-sm"
                      hasError={errorPassword === item.name}
                    />
                    {errorPassword && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorPassword === item.name
                          ? "This field is required"
                          : ""}
                      </p>
                    )}
                  </div>
                ))}
                <div className="text-end">
                  <a
                    href={baseUrl + "/forget/password/"}
                    class="inline-flex text-xs sm:text-sm text-[#10B070] "
                  >
                    {t("Authentication.Forget")}
                  </a>
                </div>
              </div>
              <Button className="bg-[#10B070] mt-8 w-full hover:bg-[#05774f] focus:bg-[#05774f]">
                {loading ? "Loading..." : "Login"}
              </Button>
            </form>
            <div className="mt-4">
              <Label className="text-sm flex items-center justify-center">
                You don't have an account yet ?{" "}
                <span
                  className="text-[#10B070] text-sm font-bold pl-2 cursor-pointer flex items-center justify-center "
                  onClick={() => navigate(baseUrl + "/register")}
                >
                  Register
                </span>
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
