import { Suspense } from "react";
// import Loading from "../Screen/Loading";
import { Route, Navigate } from "react-router-dom";
import routesPath from "./Path";
import ErrorBoundary from "../pages/Error/ErrorBoundary";
import SLUGS from "../Resources/Slug/Slug";
import Sidebar from "../Resources/sidebar/Sidebar";
import { BrowserRouter, Routes, useLocation } from "react-router-dom";
import Config from "../Config/Config";
import Loader from "../Resources/Loader/Loader";

const isAuthenticated = Config.token;

function RequireAuth({ children, redirectTo }) {
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function NotRequireAuth({ children, redirectTo }) {
  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}

const PrivateHeaders = [...routesPath?.Private];
export const PrivateRoutes = () => (
  <BrowserRouter>
   

    <Suspense fallback={<Loader/>}>
      <Sidebar>
        <Routes>
          {PrivateHeaders?.map((c, i) => (
            <Route
              path={c.path}
              key={i + 1}
              element={
                <RequireAuth redirectTo={SLUGS._assesment}>
                  <ErrorBoundary>{c.component}</ErrorBoundary>
                </RequireAuth>
              }
            ></Route>
          ))}
        </Routes>
      </Sidebar>
    </Suspense>
  </BrowserRouter>
);

const PublicPathComponent = () => {
  const location = useLocation();

  return (
    <>
      <Suspense fallback={<Loader/>}>
        <Routes>
          {routesPath.Public.map((c, i) => (
            <Route
              path={c.path}
              key={i + 1}
              element={
                <NotRequireAuth redirectTo={SLUGS._assesment}>
                  <ErrorBoundary>{c.component}</ErrorBoundary>
                </NotRequireAuth>
              }
            />
          ))}
        </Routes>
      </Suspense>
    </>
  );
};
export const PublicPath = () => (
  <>
    <BrowserRouter>
      <PublicPathComponent />
    </BrowserRouter>
  </>
);
