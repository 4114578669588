import { lazy } from "react";
import SLUGS from "../Resources/Slug/Slug";

/* 
==========================================
 Public Router Path
==========================================
*/
const Login = lazy(() => {
  return import("../auth/Login");
});
const Register = lazy(() => {
  return import("../auth/Register");
});
const NotFound = lazy(() => {
  return import("../pages/NotFound");
});
const Forget = lazy(() => {
  return import("../auth/ForgetPassword/Forget");
});

const New = lazy(() => {
  return import("../auth/NewPassword/NewPassword");
});

/* 
==========================================
 Private Router Path
==========================================
*/
const Assesment = lazy(()=>{
  return import("../pages/evassesments/EvAssesments");
})
const EvNews = lazy(()=>{
  return import ("../pages/EvNews/EvNews")
})

const IndividualEvNews = lazy(()=>{
  return import ("../pages/EvNews/IndividualNews")
})
const SurveySummary = lazy(()=>{
  return import ("../pages/SurveySummary/SurverSummary")
})
const Survey = lazy(()=>{
  return import("../pages/fleetform/FleetForm");
})
const Partner = lazy(()=>{
  return import("../pages/Partner/Partner");
})
const IndividualPartner = lazy(()=>{
  return import("../pages/Partner/IndividualPartner");
})
const Esg = lazy(()=>{
  return import("../pages/Esg/FleetVehicle/FleetVehicleList");
})
const IndividualEsg = lazy(()=>{
  return import("../pages/Esg/FleetVehicle/IndividualFleetVehicle");
})
const VehicleDocuments = lazy(()=>{
  return import("../pages/Esg/VehicleDocuments/VehicleDocuments");
})
const FleetEsg = lazy(()=>{
  return import("../pages/Esg/FleetEsgData/FleetEsgData");
})
const EsgReports = lazy(()=>{
  return import("../pages/Esg/EsgReports/EsgReports");
})

const EvTools = lazy(()=>{
  return import("../pages/EvTools/EvTools");
})

const EvMap = lazy(()=>{
  return import("../pages/evmap/EvMap");
})
const EvMaps = lazy(()=>{
  return import("../pages/evmap/Map");
})

const Map = lazy(()=>{
  return import("../pages/evmap/Map");
})

const EvrProcess = lazy(()=>{
  return import("../pages/EvrProcess/EvrProcess");
})

const EsgDashboard = lazy(()=>{
  return import("../pages/Esg/EsgDashboard/EsgDashboard");
})
const Setting = lazy(()=>{
  return import("../pages/Setting/Setting");
})


const routesPath = {
  // Public Router Path without Authentication
  Public: [
    {
      path: SLUGS._login,
      component: <Login />,
    },
    {
      path: SLUGS._register,
      component: <Register />,
    },
    {
      path: SLUGS._forget_password,
      component: <Forget />,
    },
    {
      path: SLUGS._new_password,
      component: <New />,
    },
    {
      path: "*",
      component: <NotFound />,
    },
  ],
  Private: [
    {
      path: SLUGS._assesment,
      component: <Assesment />,
    },
    {
      path: SLUGS._landing,
      component: <Assesment />,
    },
    {
      path: SLUGS._ev_news,
      component: <EvNews />,
    },
    {
      path: SLUGS._esg,
      component: <Esg />,
    },
    {
      path: SLUGS._Individual_esg,
      component: <IndividualEsg />,
    },
    {
      path: SLUGS._vehicle_documents,
      component: <VehicleDocuments />,
    },
    {
      path: SLUGS._fleet_esg,
      component: <FleetEsg />,
    },
    {
      path: SLUGS._esg_reports,
      component: <EsgReports />,
    },
    {
      path: SLUGS._survey_summary,
      component: <SurveySummary />,
    },
    {
      path: SLUGS._survey,
      component: <Survey />,
    },
    {
      path: SLUGS._ev_partner,
      component: <Partner />,
    },
    {
      path: SLUGS._ev_tools,
      component: <EvTools />,
    },
    {
      path: SLUGS._ev_map,
      component: <EvMap />,
    },
    {
      path: SLUGS._ev_maps,
      component: <EvMap />,
    },
    {
      path: SLUGS._map,
      component: <Map />,
    },
    {
      path: SLUGS._evr_process,
      component: <EvrProcess />,
    },
    {
      path: SLUGS._esg_dashboard,
      component: <EsgDashboard />,
    },
    {
      path: SLUGS._individual_partner,
      component: <IndividualPartner />,
    },
    {
      path: SLUGS._individual_news,
      component: <IndividualEvNews />,
    },
    {
      path: SLUGS._setting,
      component: <Setting />,
    },
    {
      path: "*",
      component: <NotFound />,
    },
  ],
};

export default routesPath;
