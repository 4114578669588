import React from "react";
import { t } from "i18next";
import "./Style/Error.css";
function ErrorMessage() {
  return (
    <div>
      <div class="numContainer">
        <div class="bit"></div>
        <div class="num four">
          <p>5</p>
          <hr />
        </div>
        <div class="bit"></div>
        <div class="bit"></div>
        <div class="num five">
          <p>0</p>
          <hr />
        </div>
        <div class="bit"></div>
        <div class="bit"></div>
        <div class="num nine">
          <p>0</p>
          <hr />
        </div>
        <div class="bit"></div>
        <p></p>
        <h2>{t("Error.first_text")}</h2>
        <p>{t("Error.second_text")}</p>
        <button className="button">{t("Error.contact_us")}</button>
      </div>
    </div>
  );
}

export default ErrorMessage;
