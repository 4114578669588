import Cookies from "universal-cookie";

const cookie = new Cookies();
const authToken = cookie.get("@ev_hub_access_token");
const user_group = cookie.get("@user_group");

const api =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_V
    : process.env.REACT_APP_PRODUCTION_V;

// const api = process.env.REACT_APP_PRODUCTION_V;

const config = {
  newApi: {
    addDriver: `${api}iflota/drivers/create/`,
    addCar: `${api}iflota/cars/create/`,

    car: `${api}df/cars/`,
    update_car: `${api}cars/`,
    trip_car: `${api}evhub/cars/`,
    carMarket: `${api}carmarket`,
    claims: `${api}iflota/claims/`,
    claimsDashboard: `${api}iflota/claims/dashboard/`,
    driver: `${api}df/drivers/`,
    df_driver: `${api}drivers/`,
    carmap: `${api}trips/car/`,
    importDriver: `${api}df/drivers-import/`,
    importCar: `${api}df/cars-import/`,
    driverSegment: `${api}drivers/segment/`,
    segmentValue: `${api}fleets/segment-api/`,
    _mpk_list: `${api}fleets/mpk-data/`,
    car_lists: `${api}cars/car-list/`,
    _vehicle_group: `${api}fleets/vehicle-group/`,
    addFine: `${api}df/driver/fines/`,
    addNotes: `${api}df/car_driver/notes/`,
    addCarDriver: `${api}cars/car_driver/`,
    additional: `${api}df/car/additional/`,
    document: `${api}df/car_driver/image/`,
    driverDocument: `${api}df/car_driver/driver/image/`,
    myFleetAPI: `${api}fleets/my/`,
    market_place: `${api}df/market/place/`,
    blog_education: `${api}iflota/education/`,
    costs: `${api}costs/`,
    costsUpload: `${api}costs/upload/`,
    costsNewTemplate: `${api}costs/template/`,
    reports: `${api}report/`,
    reportsInfo: `${api}report/information/`,
    resetPassword: `${api}users/changeForgottenPassword/`,
    fleet_token: `${api}users/validate-token/`,
    nip_validation: `${api}users/validate-token/`,
    forgetPassword: `${api}users/sendForgottenPasswordEmail/`,
    notification: `${api}notifications/df/fleet/`,
    delteNotification: `${api}notifications/fleet/`,
    todayNotification: `${api}notifications/today-notifications/`,
    user: `${api}users/me/dashboard/`,
    fuel_card: `${api}fuel/card/`,
    assignCard: `${api}fuel/card/assignment/`,
    task: `${api}tasks/`,
    policies: `${api}iflota/policies/`,
    my: `${api}fleets/my/`,
    esgTable: `${api}evhub/car-annual-stats/`,

    // _mpk_list: `${api}fleets/mpk-data/`,
    _car_mpk_list: `${api}cars/car-mpk/`,
    update_password: `${api}users/update-password/`,
    company_profile: `${api}fleets/insurance-profile/`,
    segment: `${api}drivers/segment/`,
    dataSegment: `${api}fleets/segment-api/`,
    anomalies: `${api}anomalies/anomalies/`,
    update_status: `${api}anomalies/update-status/`,
    notDone: `${api}anomalies/not-done/`,
    lastLocation: `${api}df/last_position/`,
    trip_data: `${api}trips/car/`,
    single_trip: `${api}trips/`,
    survey_Data: `${api}df/ev/survey/`,
    survey_Summary: `${api}df/ev/survey/data/`,
    partner: `${api}fleets/partner/`,
    news: `${api}df/education/`,
    _esg_data: `${api}evhub/fleet/analytics/`,
  },
  apiUrl: {
    loginAPI: `${api}oauth/evhub/dashboard/`,
    loginAPI1: `${api}oauth/token/dashboard/`,
    accessTokenAPI: `${api}users/accesstoken/test/`,
    logoutAPI: ` ${api}oauth/revoke_token/`,
    userMeAPI: `${api}users/me/`,
    requestOtpAPI: `${api}users/request/code/`,
    confirmOtpAPI: `${api}users/confim/code/`,
    newpassword: `${api}users/confim/code/`,
    changePassword: `${api}users/update-password/`,
    campaignn: `${api}campaigns/`,
  },

  token: authToken,
  Group: user_group,

  head: {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
  },

  headDelete: {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Typ": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
  },

  headeJust: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": window.localStorage.i18nextLng,
    Authorization: "Bearer " + authToken,
  },

  fakeHead: {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },

  headPostAxios: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": window.localStorage.i18nextLng,
    Authorization: "Bearer " + authToken,
  },
  headPostFileupload: {
    "Content-Type": "multipart/form-data",
    "Accept-Language": window.localStorage.i18nextLng,
    Authorization: "Bearer " + authToken,
  },
};

export default config;
